import {useParams, NavLink  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";
import UserService from "../services/UserService";
import PIMServices from "../services/PIMServices";
import ProductService from "../services/ProductService";
import DropdownComponent from "./DropdownComponent";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'; // Import the CSS for styling
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'
import MyLoader from "./MyLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//import 'semantic-ui-css/semantic.css';
function WorkPlan() {
  const { appslug } = useParams();
  const formatDateTime = (date) => {
    if (!date) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  document.body.style.backgroundColor = '#FFFFFF';
  const [scheduleMsg, setScheduleMsg] = useState("");
 // const [selectedDate, setSelectedDate] = useState(null);
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
 
  const [scheduleISBN, setScheduleISBN] = useState("");
  const [totalISBNCount, setTotalISBNCount] = useState("");
  const [assistantID, setAssistantID] = useState("");
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState();
  const [attributesList, setAttributesList] = useState();
  const [monthDay, setMonthDay] = useState();
  const [showSEOIntervals, setShowSEOIntervals] = useState(false);
  const [showMassSEOCriteria, setShowMassSEOCriteria] = useState(true);
  const [showMassSEOSchedule, setShowMassSEOSchedule] = useState(true);
  const [selectedTime, setSelectedTime] = useState();
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState();
  const [saveMassFrequencyMsg, setSaveMassFrequencyMsg] = useState();
  const [lastCronTime, setLastCronTime] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [attributeList, setAttributeList] = useState([]);
  const [primaryAttributeList, setPrimaryAttributeList] = useState([]);
  const [secondaryAttributeList, setSecondaryAttributeList] = useState([]);
  const [attributeSEOStatsTotal, setAttributeSEOStatsTotal] = useState();
  const [attributeSEOStats, setAttributeSEOStats] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [massAttributeIDs, setMassAttributeIDs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [savedProductAttributeGroup, setSavedProductAttributeGroup] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [showCriteriaStats, setShowCriteriaStats] = useState(false);
  const [statsSEOGenerated, setStatsSEOGenerated] = useState("");
  const [statsSEOTotal, setStatsSEOTotal] = useState("");
  const [showAutoSEONextStep, setShowAutoSEONextStep] = useState(false);
  const [showManualSEONextStep, setShowManualSEONextStep] = useState(false);
  const [includeAllCriteria, setIncludeAllCriteria] = useState("no")
  const [atrributeItemWaiting, setAtrributeItemWaiting] = useState(false)
  const [totalProductsInCriteria, setTotalProductsInCriteria] = useState(0)
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [showScheduleForLater, setShowScheduleForLater] = useState(false);
  const [SEOGenerateChanged, setSEOGenerateChanged] = useState("no")
  const [savedWorkPlanID, setSavedWorkPlanID] = useState(0)
  const [customMessage, setCustomMessage] = useState("")
  const [primaryAttributeID, setPrimaryAttributeID] = useState("")
  const [allAttributGroupIDs, setAllAttributGroupIDs] = useState([])
  
  //console.log("attributeList", attributeList)

  

  useEffect(() => {

    setSaveFrequencyMsg("")

    PIMServices.PIMAttributes(organizationID).then(
      (response) => {
        setAttributesList(response.data)
      }
    ).catch(
      (error) => {
        console.log("error fetching attributes", error);
      }
    )

    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
          setAssistantID(assistresponse.assistantID)
      }
    ).catch(
      (error) => {
        console.log("error getting assitant ID", error);
      }
    )


    PIMServices.GetPIMFrequency(organizationID).then(
      (response) => {
        const primaryID = response.attributes[0].id
        setPrimaryAttributeID(primaryID);
        const secondaryID = response.pimfrequency.attribute_ids.split(',').map(id => parseInt(id.trim(), 10))
        setAllAttributGroupIDs(secondaryID);
        
        secondaryID.filter((id) => id !== primaryID).map((attribID, keys) => {
          
          AssistantService.GetAttributeGroupValuesById(attribID).then(
          (responsee) => {
            //console.log("attribID", attribID, responsee.data.groupname, keys); 
            setSecondaryAttributeList(prevList => {
            const newGroup = {
              key: keys,
              id: attribID,
              name: responsee.data.groupname,
              items: ""
            };
            const updatedList = prevList.filter(group => group.id !== attribID);
               return [...(updatedList || []), newGroup];
            });
          }
          
        ).catch(
          (error) => {
            console.log("Error", error)
          } 
        )
        })
        //setAllAttributGroupIDs(response.attributes.filter((id) => id !== primaryID));
        //const allAttribGropID = response.pimfrequency.attribute_ids.split(',').map(id => parseInt(id.trim(), 10));
        AssistantService.GetAttributeGroupValuesById(primaryID).then(
          (responsee) => {
            setPrimaryAttributeList({
              id: primaryID,
              name: responsee.data.groupname,
              items: [
                { value: parseInt(primaryID+"0000000000", 10), text: "All" }, // Use `0` for the "All" option
                ...Array.isArray(responsee.data.attributes)
                  ? responsee.data.attributes.map(val => ({
                      value: val.id,
                      text: val.attribute_value
                    }))
                  : []
              ]
            })
          }
          
        ).catch(
          (error) => {
            console.log("Error", error)
          } 
        )




      }
    ).catch(
      (error) => {
        console.log("Error", error)
      }
    )
    
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    ).catch(
      (error) => {

      }
    )

  }, [appslug])
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }));
  };

  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value);
  }

  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value);
  };
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Pad single digits with a leading zero
    return `${hour}:00`;
  });
  const handleTimeChange = (event) => {
     setSelectedTime(event.target.value+':00');
    
  };
  const { t } = useTranslation();
  const saveSEOCriteriaAndSchedule = () => {
    
    AssistantService.SetSEOCriteriaAndSchedule(organizationID, assistantID, selectedDate, selectedOption.join(',')).then(
      (response) => {
        //console.log(response);
        setSaveMassFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO Mass frequency", error);
      }
    )
  }

  const saveSEOFrequency = (schedulenow) => {
    const params = {
      frequency : dailyWeeklyMonthly,
      days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
      time : selectedTime,
    }
    //console.log("saveSEOFrequency", organizationID, assistantID, dailyWeeklyMonthly, params.days, params.time, selectedOption.join(','), schedulenow, includeAllCriteria, totalProductsInCriteria)
    AssistantService.SetSEOFrequency(organizationID, assistantID, dailyWeeklyMonthly, params.days, params.time, selectedOption.join(','), schedulenow, formatDateTime(selectedDate), includeAllCriteria, totalProductsInCriteria, savedWorkPlanID).then(
    //AssistantService.SetSEOFrequency(organizationID, assistantID, formatDateTime(selectedDate), selectedOption.join(','), schedulenow, includeAllCriteria, totalProductsInCriteria).then(
      (response) => {
        //console.log(response);
        setSaveFrequencyMsg(t('workplaninfosaved'));
        setSavedWorkPlanID(response.workplanid);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO frequency", error);
      }
    )
    //console.log(dailyWeeklyMonthly, selectedDays, monthDay, selectedTime.format('hh:mm:ss'));
  }
  

  const handleChange = (event) => {
    setScheduleISBN(event.target.value);
  };

  const addISBNSchedule = (schedulenow) => {
    ProductService.addSchedule(organizationID, assistantID,  formatDateTime(selectedDate), scheduleISBN, schedulenow).then(
      (response) => {
        setSaveFrequencyMsg(t('workplaninfosaved'));
      }
    ).catch(
      (error) => {
        //setScheduleMsg(error.response.data.error);
        console.log("Error setting schedule", error);
      }
    )
  }
  
  
   

    
  const calculateISBNCount = () => {
    setTotalISBNCount(scheduleISBN.split(',').filter(isbn => isbn.trim() !== "").length)

  }
  //console.log("secondaryAttributeList", secondaryAttributeList)

  const handlePrimaryDropDownChange = (event, data, attribGroupID) => {
    //console.log("data.value", data.value)
    setSelectedOption(data.value)
    document.getElementById('seostats-text').value = data.value;
    AssistantService.GetAttributeValueSEOStats(data.value, assistantID, organizationID).then(
      (response) => {
        setAtrributeItemWaiting(true)
        setSecondaryAttributeList([])
        allAttributGroupIDs.filter((id) => id !== primaryAttributeID).map((attribID, keys) => {
          //console.log("attribID", attribID, response.data.productatttributes)
          response.data.productatttributes.filter((group) => group.attribute_group == attribID).map((group, key) => {
            //console.log(data.value, "data.value")
            if(data.value == "" && attribGroupID == primaryAttributeID){
              //console.log("inside");
              setShowCriteriaStats(false);
              setSelectedOption("")
              document.getElementById('seostats-text').value = "";
              setSecondaryAttributeList(prevList => {
              const groupedAttributes = {
                key: keys,
                id: attribID,
                name: group.attribute_groupname,
                items: ""
              };
              const updatedList = prevList.filter(group => group.id !== attribID);
                 return [...(updatedList || []), groupedAttributes];
              });
            } else {
              //console.log("outisde");
              setSecondaryAttributeList(prevList => {
              const groupedAttributes = {
                key: keys,
                id: attribID,
                name: group.attribute_groupname,
                items: [
                      { value: parseInt(`${group.attribute_group}0000000000`, 10), text: "All" },
                      ...group.attributes.map(attr => ({
                          value: attr.attribute_value,
                          text: attr.name 
                      }))
                  ]
              };
              const updatedList = prevList.filter(group => group.id !== attribID);
                 return [...(updatedList || []), groupedAttributes];
              }); 
              
            }
            
          })
        })
        setAtrributeItemWaiting(false)
        setShowCriteriaStats(true)
        setTotalProductsInCriteria(response.data.totalProducts-response.data.seoGeneratedCount)
        setCustomMessage(response.data.customessage)
        setStatsSEOGenerated(response.data.seoGeneratedCount)
        setStatsSEOTotal(response.data.totalProducts)
      }
    ).catch(
      (error) => {
        setShowCriteriaStats(false)
        console.log("error fetching data from GetAttributeValueSEOCount", error)
        //document.getElementById('seostats-number').innerHTML = "";
        document.getElementById('seostats-text').value = "";
        setMassAttributeIDs(prevArray => {
            // Filter out the entry with the specified attribGroupID
            const updatedArray = prevArray.filter(entry => entry.attribGroupID !== attribGroupID);

            return updatedArray;
        });
      }
    )
  };
  const handleSecondaryDropDownChange = (event, data, attribGroupID) => {
    console.log(data.value)
    setSelectedOption(data.value)
    document.getElementById('seostats-text').value = data.value;
    AssistantService.GetAttributeValueSEOStats(data.value, assistantID, organizationID).then(
      (response) => {
        console.log(response.data)
        setTotalProductsInCriteria(response.data.totalProducts-response.data.seoGeneratedCount)
        setCustomMessage(response.data.customessage)
        setStatsSEOGenerated(response.data.seoGeneratedCount)
        setStatsSEOTotal(response.data.totalProducts)
      }
    ).catch(
      (error) => {

      }
    )
  }
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('workschedule')}</h1>
                      <div className="breadcrumb">
                        {t('workschedule')} 
                      </div>
                    </div>
                    <CreditBar  creditUsed={creditUsed} totalCredit={totalCredit} /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('workschedulepagesubline')}</p>
                    </div>
                  </div>
                  <div className="container-fluid">
                  <hr />
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="">
                        <h4 className="h4">{t('setupworkplan')}</h4>
                      </div>
                    </div>
                     
                    {showAutoSEONextStep || showManualSEONextStep ? 
                      (
                      <>
                       
                        <div className="row" >
                          <div className="mt-4">
                            {showAutoSEONextStep ?
                            (
                            <>
                              {totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? 
                              (
                              <>
                                {t('thisworkplanwillconsume')} {includeAllCriteria=="yes" ? statsSEOTotal : statsSEOTotal-statsSEOGenerated} {t('consumecredits')}
                              </>
                              ) : (
                              <>
                                <p className="red">{t('thisworkplanrequire')}  {includeAllCriteria=="yes" ? statsSEOTotal : statsSEOTotal-statsSEOGenerated} {t('consumecredits')}. {t('youhave')} {totalCredit-creditUsed} {t('available')}.</p>
                              </>
                              )
                              }
                            </>
                            ) : (
                            <>
                              {totalCredit-creditUsed > totalISBNCount ? 
                                (
                                <>
                                 {t('thisworkplanwillconsume')} {totalISBNCount} {t('consumecredits')}
                                </>
                                ) : (
                                  <>
                                  <p className="red">{t('thisworkplanrequire')} {totalISBNCount} {t('consumecredits')}. {t('youhave')} {totalCredit-creditUsed} {t('available')}.</p>
                                  </>
                                )
                              }
                            </>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4" >
                          <div className="float-left w-auto pl-0 ">
                            <input name="cron_start_date" checked={showScheduleForLater==true ? "" : "checked"} value="now" onClick={() => { setShowScheduleForLater(false); setSelectedDate(new Date())}} disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} type="radio" className="checkbox" />
                          </div>
                          <div className="float-left w-auto p-0 lh-sm">
                           {t('startnow')}
                          </div>
                        </div>
                        <div className="row mt-4" >
                          <div className="float-left w-auto pl-0 ">
                            <input name="cron_start_date" value="later" checked={showScheduleForLater==true ? "checked" : ""} onClick={() => setShowScheduleForLater(true)} disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} type="radio" className="checkbox" />
                          </div>
                          <div className="float-left w-auto p-0 lh-sm">
                           {t('scheduleforlater')}
                          </div>
                          <div className="float-left w-auto pl-0 " style={{marginTop: '-9px'}}>
                            {showScheduleForLater ? 
                              <><DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={60}
                              timeCaption="Time"
                              className="form-control" 
                              dateFormat="MMMM d, yyyy h:mm aa"
                              placeholderText="Select a date and time"
                            />
                            
                            </> : "" }
                          </div>
                        </div>
                        {/*
                        <div className="row mt-4" >
                          <div className="float-left w-auto ">
                            <button disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} onClick={() => saveSEOFrequency('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                          </div>
                          
                          <div className="float-left mt-1 w-auto">
                            <div  onClick={() => setShowScheduleForLater(!showScheduleForLater)} style={{color: "#95508F ", cursor: "pointer"}}>{t('scheduleforlater')}</div>
                            <button  onClick={() => setShowScheduleForLater(!showScheduleForLater)} className="btn btn-md fm-btn me-md-2">{t('scheduleforlater')}</button> 
                          </div>
                          <div className="float-left mt-1 w-auto">
                            {showScheduleForLater ? 
                              <><DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={60}
                              timeCaption="Time"
                              className="form-control" 
                              dateFormat="MMMM d, yyyy h:mm aa"
                              placeholderText="Select a date and time"
                            />
                            
                            </> : "" }
                          </div>
                        </div>
                        
                        <div className="row" >
                          <div className="mt-4">
                            
                          </div>
                        </div>
                        */}
                        <div className="row mt-4" >
                          <div className="float-left w-auto pl-0 ">
                            <input type="checkbox" value="yes" checked={SEOGenerateChanged=="yes" ? "checked" : ""}  onChange={(e) => {setSEOGenerateChanged(e.target.checked ? "yes" : "no")}} name="continousseo" className="checkbox" />
                          </div>
                          <div className="float-left w-auto p-0 lh-sm">
                           {t('continouschangedseogenerate')}
                          </div>
                          
                        </div>
                        <div className="row mt-2" >
                          
                          <div className="col-8 px-5 h6">
                          {t('chooseintervalsubline')}
                          </div>
                          
                        </div>
                        {SEOGenerateChanged=="yes" ? 
                        (<>
                          
                          <div className="row mt-3" >
                          <div className="col-1" >
                            <br />
                            <select className="form-control" onChange={handleSelectChange}>
                              <option value="">{t('select')}</option>
                              <option value="hourly" selected={dailyWeeklyMonthly=="hourly" ? "selected" : ""}>{t('hourly')}</option>
                              <option value="daily" selected={dailyWeeklyMonthly=="daily" ? "selected" : ""}>{t('daily')}</option>
                              <option value="weekly" selected={dailyWeeklyMonthly=="weekly" ? "selected" : ""}>{t('weekly')}</option>
                              <option value="monthly" selected={dailyWeeklyMonthly=="monthly" ? "selected" : ""}>{t('monthly')}</option>
                            </select>
                          </div>
                          {/*<div className="col-1" >
                          <h6 className="h5">OR</h6>
                          </div>
                          <div className="col-4" >
                          {showAutoSEONextStep ? 
                            (
                            <>
                              <button disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} onClick={() => saveSEOFrequency('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                            </>
                            ) : (
                            <>

                              <button disabled={totalCredit-creditUsed > totalISBNCount ? "" : "disabled"} onClick={() => addISBNSchedule('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                            </>
                            )}
                          </div> */}
                          <div className="col-4" >
                            {dailyWeeklyMonthly=="hourly" ?
                            (
                            <></>)
                            : dailyWeeklyMonthly=="daily" ?
                            (
                            <div className="row">
                              <div className="col-3">
                              {t('timeofday')}
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              </div>
                             </div>)
                            : dailyWeeklyMonthly=="weekly" ?
                              (
                                <div className="row">
                                  <div className="float-left w-auto px-3">
                                    <div className="row">
                                      {t('weekday')}
                                    </div>
                                    <div className="row">
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({monday: !selectedDays.monday})}>{t('monday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({tuesday: !selectedDays.tuesday})}>{t('tuesday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({wednesday: !selectedDays.wednesday})}>{t('wednesday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({thursday: !selectedDays.thursday})}>{t('thursday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({friday: !selectedDays.friday})}>{t('friday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({saturday: !selectedDays.saturday})}>{t('saturday')}</div>
                                      <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({sunday: !selectedDays.sunday})}>{t('sunday')}</div>
                                    </div>
                                    
                                  </div>
                                  <div className="float-left w-25">
                                    <div className="row">
                                      {t('timeofday')}
                                    </div>
                                    <div className="row">
                                      <div className="col-12 p-0">
                                      <select className="form-control" onChange={handleTimeChange} >
                                        {times.map((time, index) => (
                                          <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            : dailyWeeklyMonthly=="monthly" ?
                              (<div className="row">
                                <div  className="col-3">
                                {t('date')}
                                <select className="form-control" onChange={handleMonthDayChange}>
                                  <option value="">{t('select')}</option>
                                  {days.map((day) => (
                                    <option key={day} value={day} selected={monthDay==day ? "selected" : ""}>
                                      {day}
                                    </option>
                                  ))}
                                </select>
                                </div>
                                <div className="col-3">
                                {t('timeofday')}
                                  <select className="form-control" onChange={handleTimeChange} >
                                    {times.map((time, index) => (
                                      <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                  
                                </div>
                              </div>)
                            : ''
                            }
                          </div>
                          
                        </div>
                        </>)
                        
                        : ''}
                        
                        <div className="row pt-4" >
                          <div className="col-12">
                            {showAutoSEONextStep ? 
                            (
                            <>
                              <button  disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => saveSEOFrequency('schedule')} >{t('save')}</button>
                            </>
                            ) : (
                            <>
                              <button  disabled={totalCredit-creditUsed > totalISBNCount ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => addISBNSchedule('schedule')}  >{t('save')}</button>
                            </>
                            )}
                            
                            {saveFrequencyMsg ? saveFrequencyMsg : ''}
                          </div>
                        </div>
                        <div className="row pt-4" >
                          <div className="col-12">
                            <button className="btn btn-md fm-btn me-md-2" type="button" onClick={() => {setShowAutoSEONextStep(false);  setSaveFrequencyMsg(""); setShowManualSEONextStep(false);}} >{t('previousstep')}</button>  
                          </div>
                        </div>
                      </>
                      )
                      : (
                      <>
                      <div className="row" >
                        <div className="mt-4">
                          <h6 className="h5">{t('selectcriteriaformassupdates')}</h6>
                          {/* }<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowMassSEOCriteria(!showMassSEOCriteria)} >{t('selectcriteriaformassupdates')}</button> {*/}
                        </div>
                      </div>

                      {showMassSEOCriteria ? 
                      (
                      <>
                      <div className="row pt-4" >
                        <div className="col-6" >
                          <div className="row pt-2">
                            <div className="col-3 attribute-label">
                             {primaryAttributeList.name ? primaryAttributeList.name+" :" : ""}
                            </div>
                            <div className="col-9">
                              {primaryAttributeList.items ? <Dropdown value={selectedOption} options={primaryAttributeList.items} onChange={(event, data) => handlePrimaryDropDownChange(event, data, primaryAttributeList.id)}   placeholder={"Velge "+primaryAttributeList.name}  fluid search multiple clearable selection   /> : ''}
                            </div>
                          </div> 

                          {atrributeItemWaiting ? ( <><MyLoader /></>  ) : secondaryAttributeList ? secondaryAttributeList.sort((a, b) => a.key - b.key).map((value, key) => {
                            return <>
                                <div className="row pt-2">
                                  <div className="col-3 attribute-label">
                                   {value.name+" :"}
                                  </div>
                                  <div className="col-9">
                                    <Dropdown value={selectedOption} options={value.items}  onChange={(event, data) => handleSecondaryDropDownChange(event, data, value.id)}  placeholder={"Velge "+value.name}  fluid search multiple clearable selection   />
                                  </div>
                                </div> 
                                </>
                          }) : ''}   
                          
                        </div>
                      </div>
                      <div className="row" >
                        <div className="col-12 pt-4"  >
                          {customMessage ? customMessage : ''}
                          {showCriteriaStats && statsSEOTotal ?
                            (
                            <>
                              <div className="col-6" >
                                <div>
                                It is {statsSEOTotal-statsSEOGenerated} {statsSEOTotal-statsSEOGenerated > 1 ? "products" : "product"} that fit your criteria
                                </div>
                                <div className="row mt-2">
                                  <div className="float-left pl-0 w-auto">
                                    <input className="checkbox" type="checkbox" checked={includeAllCriteria=="yes" ? "checked" : ""} value={includeAllCriteria} onChange={(e) =>{ setTotalProductsInCriteria(e.target.checked ? statsSEOTotal : statsSEOTotal-statsSEOGenerated); setIncludeAllCriteria(e.target.checked ? "yes" : "no")}} />&nbsp; 
                                  </div>
                                  <div className="float-left p-0 w-auto">
                                    Also include already optimized products ({statsSEOGenerated} optimized, total {statsSEOTotal})
                                  </div>
                                </div>
                              </div>
                            </>
                            )
                          : ""}
                           
                           
                           <div className="col-6" >
                           <input size="50" type="hidden" id="seostats-text" />
                           </div>
                        </div>
                      </div>
                      <div className="row" >
                        <div className="col-12 pt-4"  >
                          <div className="col-12">
                            <button disabled={selectedOption.length > 0 ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => setShowAutoSEONextStep(true)} >{t('nextstep')}</button>     
                          </div>
                        </div>
                      </div>
                      </>
                      )
                      : ""}
                    </>)}
                    
                    {showSEOIntervals ? 
                    (
                    <>
                    
                    </>
                    )
                    : ''}
                  </div>
                  {showAutoSEONextStep || showManualSEONextStep ? (<></>) : 
                    (
                      <>
                        <div className="container-fluid mt-4">
                          <hr />
                        </div>
                        <div className="container-fluid mt-4">
                          {/* <div className="row" >
                            <div>
                              <h4 className="h4">{t('maunalseooptimize')}</h4>
                            </div>
                          </div> */}
                          <div className="row" >

                            <div className="col-6 pt-4" >
                              <div className="col-12"><h5>{t('schedulebyproduct')}</h5></div>
                              <div className="col-12">
                                <div className="input-group mb-3">
                                  <textarea className="form-control" onChange={handleChange}  >
                                  
                                  </textarea>
                                  {/*
                                    <input type="text" value={scheduleISBN} onChange={handleChange} className="form-control" placeholder="" aria-label="" aria-describedby="" />
                                  <button onClick={() => addSchedule()} className="btn fm-btn" type="button">{t('startupdate')}</button> */}
                                </div>
                              </div>
                              {scheduleMsg ? scheduleMsg :''}
                            </div>
                          </div>
                          <div className="row" >
                            <div className="col-12 pt-4"  >
                              <div className="col-12">
                                <button disabled={scheduleISBN.length > 0 ? "" : "disabled"} onClick={() => addISBNSchedule('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                                {saveFrequencyMsg ? 'saveFrequencyMsg' : ''}
                                {/* <button disabled={scheduleISBN.length > 0 ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => { setShowManualSEONextStep(true); calculateISBNCount() } } >{t('nextstep')}</button>   */}  
                              </div>
                            </div>
                          </div>
                        </div>
                        </>
                    )
                  }
                  
                  
                  
                
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default WorkPlan;
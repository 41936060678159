import {useNavigate  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function LoginCheck() {
	 let navigate  = useNavigate();
	const userEmail = window.sessionStorage.getItem('email');
      //console.log("userEmail", userEmail)
      useEffect(() => {
	      const userEmail = window.sessionStorage.getItem('email');
	      if (userEmail) {
	        //navigate("/dashboard")
	      } else {
	      	navigate("/")
	      }
	    }, [userEmail])
      
	return (
        <div align="center">
            
        </div>
        )
}
export default LoginCheck;
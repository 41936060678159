import React, { useState } from 'react';

import UserService from "../services/UserService";

function Execute() {
	const [executeMessage, setExecuteMessage] = useState(null);
	const [formData, setFormData] = useState({
        isbn: '',
    });
	const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setExecuteMessage("Execution in progress");
        UserService.execute(formData.isbn).then(
	        (response) => {
                console.log(response);
	           setExecuteMessage(response.data.response);
	        },
	        (error) => {
	          setExecuteMessage(error.response.data.error);
	        }
        );
        
    }
	return (
        <div align="center">
        	
        	<h1>Execute</h1>
        	<form  onSubmit={handleSubmit}>
        	<div>
        	ISBN: <input type="text"  name="isbn" value={formData.isbn} onChange={handleChange} />
        	</div>
        	<div>
             <button type="submit">Execute</button>
            </div>
        	</form>
        	<div>
        	{executeMessage ? executeMessage :''}
        	</div>
        </div>
        )
}
export default Execute;
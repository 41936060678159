import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Assuming you use react-i18next for translations
import CloseCircle from "../images/cross-circle.png";; // Adjust the path as per your project structure

function JobDescriptionQuestion({ tableId, rows, addRow, removeRow, handleInputChange }) {
  const { t } = useTranslation(); // Initialize translation hook


  return (
    <>
    
    <table className="table table-hover align-middle">
      <tbody>
        
        {rows.map((row, index) => (
          <tr key={row.id} className={row.id}>
            <td>
              <input
                readonly="true"
                type="text"
                name={`description_${row.id}`}
                className="form-control"
                placeholder=""
                aria-label=""
                aria-describedby=""
                value={row.description}
                onChange={(e) => handleInputChange(row.id, 'description', e.target.value)}
              />
            </td>
            
            
          </tr>
        ))}
      </tbody>
    </table>
    </>
  );
}
export default JobDescriptionQuestion;
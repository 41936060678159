import axios from "axios";

class AuthService {
    
    
    login(email, password) {
        const data = {
            email: email,
            password: password
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "login",
        } 
        return axios(options)
        .then(response => {
           // console.log("response", response);
            //window.sessionStorage.setItem(
            return response;
        })

    }

    saveToken(email, token) {
        const data = {
            email: email,
            access_token: token
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "savetoken",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    register(postedData) {
        /*const data = {
            email: email,
            password: password
        }*/
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: postedData,
            url: process.env.REACT_APP_API_SERVER_URL + "register",
        } 
        return axios(options)
        .then(response => {
           // console.log("response", response);
            //window.sessionStorage.setItem(
            return response;
        })
    }
    
    

}

export default new AuthService();
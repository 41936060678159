import {useNavigate  } from 'react-router-dom';
import React, { useState } from 'react';
import CloseCircle  from "../images/cross-circle.png";
import { useTranslation } from 'react-i18next';


function LanguageSection() {
  const { t } = useTranslation();
  
	return (
        <div  >
        <div className="col-12 fm-content-box p-4">
            <div className="col-12">
              <table className="table align-middle">
               <tbody>
                  <tr>
                    <td><h3>{t('language')}</h3></td>
                    <td className="col-2" align="right"></td>
                  </tr>
                   </tbody>
                </table>
            </div>
            <div className="col-12 table-responsive mt-4 fm-table">
            
            </div>
             </div>     
        </div>
        )
}
export default LanguageSection;
import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import ProductService from "../services/ProductService";
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import ThumbUpBlank  from "../images/thumb-up-blank.png";
import ThumbUp from "../images/thumb-up.png";
import ThumbDown  from "../images/thumb-down.png";
import Delete  from "../images/delete.png";
import InfoIcon  from "../images/info.png";
import JobDesc  from "../images/job-description.png";
import CloseIcon  from "../images/close.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';

function CompletedTask() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showManualApprove, setShowManualApprove] = useState(false);
 
  const [totalPages, setTotalPages] = useState("");
  const [outputCount, setOutputCount] = useState("");
  const [totalProducts, setTotalProducts] = useState("");
  const [totalProductss, setTotalProductss] = useState("");
  const [productList, setProductList] = useState("");

  const [MTFtotalPages, setMTFTotalPages] = useState("");
  const [MTFoutputCount, setMTFOutputCount] = useState("");
  const [MTFtotalProducts, setMTFTotalProducts] = useState("");
  const [MTFtotalProductss, setMTFTotalProductss] = useState("");
  const [MTFproductList, setMTFProductList] = useState("");

  const [RTFtotalPages, setRTFTotalPages] = useState("");
  const [RTFoutputCount, setRTFOutputCount] = useState("");
  const [RTFtotalProducts, setRTFTotalProducts] = useState("");
  const [RTFtotalProductss, setRTFTotalProductss] = useState("");
  const [RTFproductList, setRTFProductList] = useState("");

  const [InCompletetotalPages, setInCompleteTotalPages] = useState("");
  const [InCompleteoutputCount, setInCompleteOutputCount] = useState("");
  const [InCompletetotalProducts, setInCompleteTotalProducts] = useState("");
  const [InCompleteproductList, setInCompleteProductList] = useState("");


  const [additionalData, setAdditionalData] = useState({});

  const [toggleState, setToggleState] = useState({});
  const [plusMinus, setPlusMinus] = useState(false);
  const [outputList, setOutputList] = useState("");
  const [thumbUped, setThumbUped] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [searchISBN, setSearchISBN] = useState("");
  const [showDescModal, setShowDescModal] = useState(false);
  const [jobDescription, setJobDescription] = useState(false);
  const [q1Question, setQ1Question] = useState();
  const [productDescription, setProductDescription] = useState();
  const [questionsData, setQuestionsData] = useState([]);
  const [assistantID, setAssistantID] = useState(false);
  const [manualApproveOutputID, setManualApproveOutputID] = useState({});
  const [manualApproveOutputIDSaved, setManualApproveOutputIDSaved] = useState({});
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [showsaveManualOutputMsg, setShowsaveManualOutputMsg] = useState();
  const [q1Rows, setQ1Rows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  
  console.log("manualApproveOutputID", manualApproveOutputID);
  const handleSearchChange = (event) => {
    // Update the state with the new input value
    setSearchISBN(event.target.value);
    
  };

  const [pagination, setPagination] = useState({
    // Example: 
    '1': { currentPage: 1, totalPages: 1 },
    '2': { currentPage: 1, totalPages: 1 },
    '3': { currentPage: 1, totalPages: 1 },
    '4': { currentPage: 1, totalPages: 1 },
  });
  //console.log("InCompletetotalPages", InCompletetotalPages)
  useEffect(() => {
    //setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        ProductService.productList(assistresponse.assistantID, 'completed', "", pagination['1'].currentPage, organizationID).then(
          (response) => {
            setOutputCount(response.data.output_count);
            setTotalProducts(response.data.total_products);
            setTotalProductss(response.data.total_productss);
            setProductList(response.data.products);
            setTotalPages(response.data.total_pages);
            if (pagination['1']?.totalPages !== response.data.total_pages) {
              updatePaginationForList('1', response.data.total_pages);
            }
            response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn, "completed");
            });
            setLoadingMsg(false);
          }
        ).catch(
          (error) => {
            console.log("Error fetching completed products", error)
             setLoadingMsg(false);
          }
        )
      }
    ).catch(
      (error) => {
        console.log("Error fetching getAssistantIDBySlug", error)
        //setLoadingMsg(false);
      }
    )
  },[appslug, pagination['1']])

  useEffect(() => {
    //setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        ProductService.productList(assistresponse.assistantID, 'readytofeed', "", pagination['3'].currentPage, organizationID).then(
          (response) => {
            setRTFOutputCount(response.data.output_count);
            setRTFTotalProducts(response.data.total_products);
            setRTFTotalProductss(response.data.total_productss);
            setRTFProductList(response.data.products);
            setRTFTotalPages(response.data.total_pages);
            console.log("response.data.total_pages", response.data.total_pages)
            if (pagination['3']?.totalPages !== response.data.total_pages) {
              updatePaginationForList('3', response.data.total_pages);
            }
           /* response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn, "readytofeed");
            });*/
            setLoadingMsg(false);
          }
        ).catch(
          (error) => {
            console.log("Error fetching completed products", error)
             setLoadingMsg(false);
          }
        )
      }
    ).catch(
      (error) => {
        console.log("Error fetching getAssistantIDBySlug", error)
        //setLoadingMsg(false);
      }
    )
  },[appslug, pagination['3']])
  useEffect(() => {
    //setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        ProductService.productList(assistresponse.assistantID, 'movedtofeed', "", pagination['4'].currentPage, organizationID).then(
          (response) => {
            setMTFOutputCount(response.data.output_count);
            setMTFTotalProducts(response.data.total_products);
            setMTFTotalProductss(response.data.total_productss);
            setMTFProductList(response.data.products);
            setMTFTotalPages(response.data.total_pages);
            if (pagination['4']?.totalPages !== response.data.total_pages) {
              updatePaginationForList('4', response.data.total_pages);
            }
            response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn, "movedtofeed");
            });
            setLoadingMsg(false);
          }
        ).catch(
          (error) => {
              //console.log("Error fetching movedtofeed products ", error)
             setLoadingMsg(false);
          }
        )
      }
    ).catch(
      (error) => {
        //console.log("Error fetching getAssistantIDBySlug", error)
        //setLoadingMsg(false);
      }
    )
  },[appslug, pagination['4']])
  

  useEffect(() => {
    setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);
        ProductService.exampleList(assistresponse.assistantID, organizationID).then(
          (response) => {
            //console.log("EX LIST", response.data);
            const newOutputIds = response.data.map(item => item.id);
            setThumbUped(prevData => {
                // Add each output_id to the state
                const newData = { ...prevData };
                newOutputIds.forEach(outputId => {
                    newData[outputId] = outputId;
                });
                return newData;
            });
            ///setLoadingMsg(false);
          }
        ). catch(
          (error) => {
            console.error("Error fetching item:", error);

          }
        )
        

        ProductService.OutputIDSavedForPush(assistresponse.assistantID, organizationID).then(
          (response) => {
            console.log("response.data.output_ids", response.output_ids);
            setManualApproveOutputIDSaved(response.output_ids);
          }
        ). catch(
          (error) => {
            console.error("Error fetching item OutputIDSavedForPush:", error);

          }
        )
        

        ProductService.productList(assistresponse.assistantID, 'incomplete', "", pagination['2'].currentPage, organizationID).then(
          (response) => {
            console.log("response.data.products", response.data.products)
            //setInCompleteOutputCount(response.data.output_count);
            setInCompleteTotalProducts(response.data.total_products-response.data.total_generated);
            setInCompleteProductList(response.data.products);
            setInCompleteTotalPages(response.data.total_pages);
            if (pagination['2']?.totalPages !== response.data.total_pages) {
              updatePaginationForList('2', response.data.total_pages);
            }
            setLoadingMsg(false);
            /*response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn);
            });*/
          }
        ). catch(
          (error) => {
            console.error("Error fetching incomplete productList:", error);
             setLoadingMsg(false);
          }
        )

    }
    )
    AssistantService.getAssistantIDBySlug(appslug, organizationID).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )
  },[appslug, pagination['2']])
  
  const deleteOutput = (outputId) => {
    const confirmed = window.confirm(t('deleteitemconfirmation'))
    if (confirmed) {
      ProductService.deleteOutput(outputId).then(
        (response) => {
          document.getElementById("outputitem_"+outputId).style.display = 'none';
          console.log(response);
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );
    }
  }

  const exampleOutput = (updown, outputId) => {
    if(updown=="up") {
    var confirmed = window.confirm(t('addtrainingdataconfirmation'));
    } else {
    var confirmed = window.confirm(t('removetrainingdataconfirmation'));
    }
    if(confirmed){
      ProductService.exampleOutput(assistantID, updown, outputId).then(
        (response) => {
         // console.log(response);
          {updown=='up' ? 
          setThumbUped(prevData => ({ ...prevData, [outputId]: outputId })) 
          : 
          setThumbUped(prevData => {
              // Remove the specified outputId
              const { [outputId]: _, ...newData } = prevData;
              return newData;
          });
        }
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );
    }
    
  }

  const productSearch = () => {

    ProductService.productList(assistantID, "completedsearch", searchISBN, 1).then(
      (response) => {

        setProductList(response.data.products);
        setTotalPages(response.data.total_pages);
        //response.data.products.forEach(book => {
          //fetchAdditionalData(book.product_isbn);
        //})
      }
    ).catch(
      (error) => {
        console.log(error)
      }
    )
  }

  const fetchAdditionalData = async (assistantID, isbn, type) => {
    try {
      ProductService.outputList(assistantID, isbn, type, organizationID).then(
      (response) => {
        //console.log(response.data);
        setAdditionalData(prevData => ({ ...prevData, [isbn]: response.data }));
      },
      (error) => {
        console.log(error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const showOutputs = (isbn) => {
    
    setToggleState(prevState => ({
      ...prevState,
      [isbn]: !prevState[isbn]
    }));
  }

  const viewProductDescription = (prodDesc) => {
    setProductDescription(prodDesc);
    setShowDescModal(true);
  }

  const viewJobDescription = (outputid, outputidd) => {
    const questionsDataa = JSON.parse(outputidd);
    setQuestionsData(questionsDataa);


    const promptData = JSON.parse(outputid);
    setQ1Question(promptData[0].question);
    const questionData = promptData[0].data;
    setQ1Rows(questionData)
    //setJobDescription(outputid)

    


    handleOpenModal();
  }

  const handleCloseProdDesc = () => {
    setShowDescModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const updatePaginationForList = (listId, totalPages) => {
    setPagination((prev) => ({
      ...prev,
      [listId]: {
        ...prev[listId], // Preserve existing state for the specific list
        totalPages: totalPages, // Update the totalPages
      },
    }));
  };

  const setPageForList = (listId, page) => {
    setPagination((prev) => ({
      ...prev,
      [listId]: {
        ...prev[listId],
        currentPage: page,
      },
    }));
  };

  const handleNextClick = (listId) => {
    if (pagination[listId].currentPage < pagination[listId].totalPages) {
      setPageForList(listId, pagination[listId].currentPage + 1);
    }
  };

  const handlePreviousClick = (listId) => {
    if (pagination[listId].currentPage > 1) {
      setPageForList(listId, pagination[listId].currentPage - 1);
    }
  };

  const handleFirstClick = (listId) => {
    setPageForList(listId, 1);
  };

  const handleLastClick = (listId) => {
    setPageForList(listId, pagination[listId].totalPages);
  };

  const generatePageNumbers = (listId) => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    const currentPage = pagination[listId].currentPage;
    const totalPages = pagination[listId].totalPages;
    
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (halfMaxPagesToShow - (currentPage - 1)));
    }

    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - ((currentPage + halfMaxPagesToShow) - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  
  const handleCheckboxChange = (outputID, isChecked) => {
    setManualApproveOutputID(prevData => {
      if (isChecked) {
        // Add outputID when checkbox is checked
        return { ...prevData, [outputID]: outputID };
      } else {
        // Remove outputID when checkbox is unchecked
        const { [outputID]: _, ...rest } = prevData; // Omit the unchecked ID
        return rest;
      }
    });
  };

  

  const saveManualOutputID = () => {
    ProductService.saveManualOutputID(assistantID, organizationID, manualApproveOutputID).then(
        (response) => {
          //setManualApproveOutputIDSaved(manualApproveOutputID);
          setShowsaveManualOutputMsg("Output scheduled for feed");
          //document.getElementById("outputitem_"+outputId).style.display = 'none';
          //console.log(response);
        }
      ).catch(
          (error) => {
              console.error("Error saveManualOutputID:", error);
          }
      );
  }
  const nl2br = (text) => {
    return text.split('\n').map((str, index) => (
      <>
        {str}
        <br />
      </>
    ));
  };
  return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('completedtask')}</h1>
                      <div className="breadcrumb">
                        {t('completedtask')}
                      </div>
                    </div>
                    <CreditBar  creditUsed={creditUsed} totalCredit={totalCredit} /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('completedtaskpagesubline')}</p>
                    </div>
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-6">
                        
                        

                      </div>
                    </div>
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      
                      <div className="col-9 navigateButton">
                        <div className="" align="right">
                          <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">

                            <li class="nav-item" role="presentation">
                              <button class="nav-link  active" id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed" aria-selected="false">{t('seooptimized')} ({totalProducts})</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link  " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('notseooptimized')} ({InCompletetotalProducts})</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link " id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">{t('readyforexport')} ({RTFtotalProducts})</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link " id="pills-service-tab" data-bs-toggle="pill" data-bs-target="#pills-service" type="button" role="tab" aria-controls="pills-service" aria-selected="false">{t('exported')} ({MTFtotalProducts})</button>
                            </li>
                          </ul>
                         {/*  <NavLink to={"/"+appslug+"/incompleted-task"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('incompletedtasks')}</button> </NavLink> 
                          */}    
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input-group mb-3">
                          <input type="text" onChange={handleSearchChange} className="form-control" placeholder={t('search')} aria-label="" aria-describedby="" />
                          <button onClick={() => productSearch()} className="btn fm-btn" type="button">{t('search')}</button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      
                         <div className="col-12 fm-content-box p-4">
                          <div className="col-12 table-responsive mt-4 fm-table">
                              <div class="tab-content" id="pills-tabContent">
                                {/* COMPLETED TAB */}
                                <div class="tab-pane fade show active" id="pills-completed" role="tabpanel" aria-labelledby="pills-completed-tab">
                                  <table className="table table-hover align-top">
                                    <tbody>
                                      <tr>
                                        <th scope="col"></th>
                                        <th scope="col">SR.</th>
                                        <th scope="col">{t('isbn')}</th>
                                        <th scope="col" className="col-2">{t('title')}</th>
                                        <th scope="col">{t(appslug)}</th>
                                        <th scope="col" align="right" style={{textAlign:'right'}} width="10%">{t('action')}</th>
                                        
                                      </tr>
                                      {loadingMsg ? 
                                        (
                                        <tr><td align="center" colspan="6">{t('loadingdata')}</td></tr>
                                        )
                                         
                                        : ''}
                                      {productList ? 
                                        productList.map((val, key) =>{
                                          return (
                                          <tr>
                                            <td valign="top">
                                            {additionalData[val.product_isbn]!=null ? ( <><div className="plus-minus" onClick={() => showOutputs(val.product_isbn) }  width="12"  >{toggleState[val.product_isbn] ? "-" : "+" } </div></>
                                                    )
                                                    : '' }</td>
                                            <td>{val.serial_number}</td>
                                            <td>{val.product_isbn}</td>
                                            <td>{val.product_name}</td>
                                            <td colspan="2">
                                              <div>
                                                <div className="outputListItemOutputFirst">
                                                  {nl2br(val.output)}
                                                </div>
                                                 
                                                <div  className="outputListItemAction">
                                                  <img className="close-btn" src={InfoIcon} onClick={() => viewProductDescription(val.product_description)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                  <img className="close-btn" src={JobDesc} onClick={() => viewJobDescription(val.prompt, val.prompt2)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                  {thumbUped[val.id] ? <img  className="close-btn" onClick={() => exampleOutput('down', val.id)}  src={ThumbUp} title={t('removeexample')}  width="18" /> : <img  className="close-btn" onClick={() => exampleOutput('up',val.id)}  src={ThumbUpBlank} title={t('setexample')} width="18" /> }
                                              
                                                </div>
                                                <div className="outputListItemAction">&nbsp;</div>
                                              </div>
                                              <div style={{clear:'both'}}></div>
                                              <div id={val.product_isbn} style={{ display: toggleState[val.product_isbn] ? 'block' : 'none' }}>
                                                  {additionalData[val.product_isbn]!=null ? (
                                                    <>
                                                    <br />
                                                    <div><b>{t('outputhistory')}</b></div>
                                                    {additionalData[val.product_isbn] ? additionalData[val.product_isbn].map((value, key) => {
                                                      return <>
                                                        <div className="outputListItem" id={"outputitem_"+value.id}>
                                                          <div className="outputListItemOutput">{nl2br(value.output)}</div> 
                                                          <div className="outputListItemAction">
                                                          <img className="close-btn" src={JobDesc} onClick={() => viewJobDescription(value.prompt, value.prompt2)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                          <img  className="close-btn" style={{display:'none'}}  src={Delete} title={t('delete')} onClick={() => deleteOutput(value.id)} width="18" />
                                                          {thumbUped[value.id] ? <img  className="close-btn" onClick={() => exampleOutput('down', value.id)}  src={ThumbUp} title={t('removeexample')} width="18" /> : <img  className="close-btn" onClick={() => exampleOutput('up',value.id)}  src={ThumbUpBlank} title={t('setexample')} width="18" /> }
                                                          </div> 
                                                          <div className="outputListItemAction">&nbsp;</div> 
                                                        </div>
                                                        
                                                      </>
                                                    })
                                                    : ''}
                                                    </>
                                                    )
                                                    : '' }
                                              </div>
                                            </td>
                                            
                                          </tr>
                                          )
                                        })
                                       : ''}
                                                                                                

                                      </tbody>
                                     <tr>
                                      <td colspan="5">
                                        {/* Pagination controls */}
                                          <button className="paging"  onClick={() => handleFirstClick('1')}>{t('first')}</button>
                                          <button className="paging"  onClick={() => handlePreviousClick('1')}>{t('previous')}</button>
                                          {generatePageNumbers('1').map((page) => (
                                            <button className={pagination['1'].currentPage === page ? 'currpaging' : 'paging'}
                                              key={page}
                                              onClick={() => setPageForList('1', page)}
                                              
                                            >
                                              {page}
                                            </button>
                                          ))}
                                          <button className="paging"  onClick={() => handleNextClick('1')}>{t('next')}</button>
                                          <button className="paging"  onClick={() => handleLastClick('1')}>{t('last')}</button>
                                    
                                      </td>
                                     </tr>
                                  </table>
                                </div>
                                {/* COMPLETED TAB */}

                                {/* INCOMPLETED TAB */}
                                <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                  <table className="table table-hover align-top">
                                   <tbody>
                                      <tr>
                                        <th scope="col"></th>
                                        <th scope="col">SR.</th>
                                        <th scope="col">{t('isbn')}</th>
                                        <th scope="col" className="col-2">{t('title')}</th>
                                        <th scope="col">{t('description')}</th>
                                        </tr>
                                        {loadingMsg ? 
                                        (
                                        <tr><td align="center" colspan="5">{t('loadingdata')}</td></tr>
                                        )
                                         
                                        : ''}
                                      {InCompleteproductList ? 
                                        InCompleteproductList.map((val, key) =>{
                                          return (
                                            <tr>
                                              <td valign="top"></td>
                                              <td>{val.serial_number}</td>
                                              <td>{val.product_isbn}</td>
                                              <td>{val.product_name}</td>
                                              <td>{val.product_description.replace(/<P>/gi, '').replace(/<[^>]*>/g, '').replace(/<!--.*?-->|<\/?p>/g, '').trim()}</td>
                                            </tr>
                                            )
                                        })
                                       : ''}
                                                                                                

                                       </tbody>
                                       <tr>
                                        <td colspan="5">
                                          {/* Pagination controls */}
                                          <button className="paging" onClick={() => handleFirstClick('2')}>{t('first')}</button>
                                          <button className="paging" onClick={() => handlePreviousClick('2')}>{t('previous')}</button>
                                          {generatePageNumbers('2').map((page) => (
                                            <button className={pagination['2'].currentPage === page ? 'currpaging' : 'paging'}
                                              key={page}
                                              onClick={() => setPageForList('2', page)}
                                              
                                            >
                                              {page}
                                            </button>
                                          ))}
                                          <button className="paging" onClick={() => handleNextClick('2')}>{t('next')}</button>
                                          <button className="paging" onClick={() => handleLastClick('2')}>{t('last')}</button>
                                      
                                        </td>
                                       </tr>
                                    </table>
                                </div>
                                {/* INCOMPLETED TAB */}

                                {/* READY TO FEED TAB */}
                                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                  
                                  <table className="table table-hover align-top">
                                    <tbody>
                                      <tr>
                                        <th scope="col"></th>
                                        <th scope="col" width="5%">SR.</th>
                                        <th scope="col"  width="10%" >{t('isbn')}</th>
                                        <th scope="col" width="20%" >{t('title')}</th>
                                        <th scope="col"  width="50%">{t(appslug)}</th>
                                        <th scope="col" align="right" style={{textAlign:'right'}} width="15%">{t('action')}</th>
                                        
                                      </tr>
                                      {loadingMsg ? 
                                        (
                                        <tr><td align="center" colspan="6">{t('loadingdata')}</td></tr>
                                        )
                                         
                                        : ''}
                                      {RTFproductList ? 
                                        RTFproductList.map((val, key) =>{
                                          return (
                                          <tr>
                                            <td valign="top">
                                            <input id={val.id} checked={!!manualApproveOutputID[val.id] || !!manualApproveOutputIDSaved[val.id]} disabled={!!manualApproveOutputIDSaved[val.id]} onChange={(e) => handleCheckboxChange(val.id, e.target.checked)} type="checkbox" className="checkbox" />
                                            </td>
                                            <td>{val.serial_number}</td>
                                            <td>{val.product_isbn}</td>
                                            <td>{val.product_name}</td>
                                            <td colspan="2">
                                              <div>
                                                <div className="outputListItemOutputFirst">
                                                  {nl2br(val.output)}
                                                </div>
                                                 
                                                <div  className="outputListItemAction">
                                                  <img className="close-btn" src={InfoIcon} onClick={() => viewProductDescription(val.product_description)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                  <img className="close-btn" src={JobDesc} onClick={() => viewJobDescription(val.prompt, val.prompt2)} title={t('viewjobdescription')} width="18" />
                                              
                                                </div>
                                                <div className="outputListItemAction">&nbsp;</div>
                                              </div>
                                              <div style={{clear:'both'}}></div>
                                              
                                            </td>
                                            
                                          </tr>
                                          )
                                        })
                                       : ''}
                                                                                                

                                      </tbody>
                                     <tr>
                                      <td colspan="5">
                                          {/* Pagination controls */}
                                          <button className="paging" onClick={() => handleFirstClick('4')}>{t('first')}</button>
                                          <button className="paging" onClick={() => handlePreviousClick('4')}>{t('previous')}</button>
                                          {generatePageNumbers('3').map((page) => (
                                            <button className={pagination['3'].currentPage === page ? 'currpaging' : 'paging'}
                                              key={page}
                                              onClick={() => setPageForList('3', page)}
                                              
                                            >
                                              {page}
                                            </button>
                                          ))}
                                          <button className="paging" onClick={() => handleNextClick('4')}>{t('next')}</button>
                                          <button className="paging" onClick={() => handleLastClick('4')}>{t('last')}</button>
                                    
                                      </td>
                                     </tr>
                                     <tr>
                                      <td colspan="5">
                                        <div className="row">
                                          <div className="col-6 pt-1">
                                            <div className="float-left w-auto ">
                                              <button className="btn btn-md fm-btn me-md-2" onClick={() => saveManualOutputID() } type="button" >{t('save')}</button>
                                              {showsaveManualOutputMsg ? showsaveManualOutputMsg : ''}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                     </tr>
                                  </table>
                                </div>
                                {/* READY TO FEED TAB */}

                                {/* MOVED TO FEED TAB */}
                                <div class="tab-pane fade" id="pills-service" role="tabpanel" aria-labelledby="pills-service-tab">
                                  <table className="table table-hover align-top">
                                    <tbody>
                                      <tr>
                                        <th scope="col"></th>
                                        <th scope="col">SR.</th>
                                        <th scope="col">{t('isbn')}</th>
                                        <th scope="col" className="col-2">{t('title')}</th>
                                        <th scope="col">{t(appslug)}</th>
                                        <th scope="col" align="right" style={{textAlign:'right'}} width="10%">{t('action')}</th>
                                        
                                      </tr>
                                      {loadingMsg ? 
                                        (
                                        <tr><td align="center" colspan="6">{t('loadingdata')}</td></tr>
                                        )
                                         
                                        : ''}
                                      {MTFproductList ? 
                                        MTFproductList.map((val, key) =>{
                                          return (
                                          <tr>
                                            <td valign="top">
                                            </td>
                                            <td>{val.serial_number}</td>
                                            <td>{val.product_isbn}</td>
                                            <td>{val.product_name}</td>
                                            <td colspan="2">
                                              <div>
                                                <div className="outputListItemOutputFirst">
                                                  {nl2br(val.output)}
                                                </div>
                                                 
                                                <div  className="outputListItemAction">
                                                  <img className="close-btn" src={InfoIcon} onClick={() => viewProductDescription(val.product_description)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                  <img className="close-btn" src={JobDesc} onClick={() => viewJobDescription(val.prompt, val.prompt2)} title={t('viewjobdescription')} width="18" />
                                              
                                                </div>
                                                <div className="outputListItemAction">&nbsp;</div>
                                              </div>
                                              <div style={{clear:'both'}}></div>
                                              
                                            </td>
                                            
                                          </tr>
                                          )
                                        })
                                       : ''}
                                                                                                

                                      </tbody>
                                     <tr>
                                      <td colspan="5">
                                          {/* Pagination controls */}
                                          <button className="paging" onClick={() => handleFirstClick('4')}>{t('first')}</button>
                                          <button className="paging" onClick={() => handlePreviousClick('4')}>{t('previous')}</button>
                                          {generatePageNumbers('4').map((page) => (
                                            <button className={pagination['4'].currentPage === page ? 'currpaging' : 'paging'}
                                              key={page}
                                              onClick={() => setPageForList('4', page)}
                                              
                                            >
                                              {page}
                                            </button>
                                          ))}
                                          <button className="paging" onClick={() => handleNextClick('4')}>{t('next')}</button>
                                          <button className="paging" onClick={() => handleLastClick('4')}>{t('last')}</button>
                                    
                                      </td>
                                     </tr>
                                  </table>
                                </div>
                                {/* MOVED TO FEED TAB */}
                              </div>
                              

                            </div>
                            
                            
                            
                         </div>
                     </div>
                     {showDescModal ? 
                     <div className="modal display-block">
                       <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img src={CloseIcon} onClick={handleCloseProdDesc} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">
                        {productDescription ? productDescription : ''}
                        </div>
                       </div>
                     </div>
                     : ''}
                     {showModal ? 
                     <div className="modal display-block">
                       <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img src={CloseIcon} onClick={handleClose} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">

                          {q1Question ? 
                            (
                            <>
                            <table className="table table-hover align-middle">
                            <tbody>
                            <tr>
                              <td colspan="2">
                              <b>{q1Question}</b>
                              </td>
                            </tr>

                            {q1Rows.map((row, index) => (
                              <>
                                <tr>
                                  <td className="col-4">
                                    <input
                                      readonly="true"
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.heading}
                                    /> 
                                  </td>
                                  <td>
                                    <input
                                      readonly="true"
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.description}
                                    /> 
                                  </td>
                                </tr>
                              </>
                            ))}
                            </tbody>
                            </table>
                            </>
                            )
                            : '' }
                            {questionsData ? (
                              <><table className="table table-hover align-middle">
                            <tbody>
                            {questionsData.map((values, index) => (
                              <>
                                <tr>
                                  <td colspan="2">
                                  <b>{values.q}</b> 
                                  </td>
                                </tr>
                                
                                  {values.a.map((val, key) => (
                                  <>
                                    <tr>
                                      <td  colspan="2">
                                        <input
                                        readonly="true"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        value={val}
                                      /> 
                                     </td>
                                    </tr>
                                  </>
                                   ))
                                  } 
                                  
                              </>
                            ))}
                            </tbody>
                            </table>
                            </>
                            )
                            : '' }
                        </div>
                       </div>
                     </div>
                     : ''}
                  </div>
                  
                  
                </main>
              </div>
            </div>
          
        </div>
        )
}
export default CompletedTask;
import axios from "axios";

class UserService {
    
    getUserFromToken(access_token) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"email": access_token},
            url: process.env.REACT_APP_API_SERVER_URL + "getuser",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    saveOutputHistory(data){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "saveoutputhistory",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    execute(orgID, userID, assistantID, isbn) {
        const data = {
            isbn: isbn,
            "assistant_id": assistantID,
            "organization_id": orgID,
            "user_id": userID
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "execute",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    OrganizationDetails(orgID) {
        const data = {
            organization_id: orgID
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "organizationdetails",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetOrganizationList() {
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "organizationlist",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    

      
    

}

export default new UserService();
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";

function AttributeNames({ idlist }) {
  const [attributeNames, setAttributeNames] = useState([]); // Holds all fetched names

  useEffect(() => {
    const ids = idlist.split(","); // Split the comma-separated list into individual IDs

    const fetchAttributeNames = async () => {
      try {
        // Use Promise.all to handle multiple API requests
        const names = await Promise.all(
          ids.map(async (id) => {
            if (id.endsWith("0000000000")) {
              // If ID ends with 10 zeros, call this function
              const response = await AssistantService.GetAttributeGroupNameById(parseInt(id.slice(0, -10), 10)); // Remove zeros and parse to int
              return "All "+response.data.attribute.attribute_value; // Adjust based on API response structure
            } else {
              // If ID does not end with 10 zeros, call a different function
              const response = await AssistantService.GetAttributeNamesByID(parseInt(id, 10)); // Call different service
              return response.data.attribute.name;
            }
          })
        );
        setAttributeNames(names); // Set the attribute names in the state
      } catch (error) {
        console.log("Error fetching attribute group names", error);
      }
    };

    fetchAttributeNames(); // Call the function to fetch data
  }, [idlist]); // Re-run when idlist changes

  return (
    <>
      <div>
        {/* Render the fetched names */}
        {attributeNames.length > 0 ? (
          attributeNames.map((name, index) => (
            <span key={index}>{name}{index !== attributeNames.length - 1 && ', '}</span> // Add commas between names
          ))
        ) : (
          <div>Loading...</div> // Display while loading
        )}
      </div>
    </>
  );
}

export default AttributeNames;

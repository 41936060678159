import {useNavigate  } from 'react-router-dom';
import React, { useState } from 'react';
import CloseCircle  from "../images/cross-circle.png";
import { useTranslation } from 'react-i18next';


function DashboardSection({ rows, setRows, tableId, title }) {
  const { t } = useTranslation();
  //console.log(rows);
  const addNewRow = () => {

    const newRow = { id: rows.length + 1, heading: '', description: '' };
    setRows([...rows, newRow]);
  };

  const removeRow = (id) => {
    const newRows = rows.filter(row => row.id !== id).map((row, index) => ({
      ...row,
      id: index + 1
    }));
    setRows(newRows);
  };

  
	return (
        <>
        
            <div className="col-12">
              <table className="table align-middle">
                  <tbody>
                  <tr>
                    <td><h3>{title}</h3></td>
                    <td className="col-2" align="right"><button className="btn fm-btn"  onClick={() => addNewRow()} type="button">{t('add')}</button></td>
                  </tr>
                </tbody>
                </table>
            </div>
            <div className="col-12 table-responsive mt-4 fm-table">
            {/*
            <div className="row  p-1">
              <div className="col-1">&nbsp;</div>
              <div className="col-2"><b>{t('heading')}</b></div>
              <div className="col-8"><b>{t('description')}</b></div>
              <div className="col-1">&nbsp;</div>
            </div>
            { rows.map((row, index) => (
            <div className="row p-2">
              <div className="col-1">{String(row.id).padStart(2, '0')} </div>
              <div className="col-2">
                <input
                    type="text"
                    name={`heading_${row.id}`}
                    className="form-control"
                    placeholder=""
                    aria-label=""
                    aria-describedby=""
                    value={row.heading}
                    onChange={(e) => {
                      const newRows = [...rows];
                      newRows[index].heading = e.target.value;
                      setRows(newRows);
                    }}
                  />
              </div>
              <div className="col-8">
                <input
                  type="text"
                  name={`description_${row.id}`}
                  className="form-control"
                  placeholder=""
                  aria-label=""
                  aria-describedby=""
                  value={row.description}
                  onChange={(e) => {
                    const newRows = [...rows];
                    newRows[index].description = e.target.value;
                    setRows(newRows);
                  }}
                />
              </div>
              <div className="col-1" align="right">
                <img className="close-btn" src={CloseCircle} title={t('remove')} onClick={() => removeRow(row.id)} alt="Close" />
              </div>
            </div>
            )) */}
            <table className="table table-hover align-middle">
              
              <tbody>
                <tr className="tr-header">
                  <td>&nbsp;</td>
                  <td><b>{t('heading')}</b></td>
                  <td><b>{t('explanation')}</b></td>
                  <td></td>
                </tr>
                {rows.map((row, index) => (
                  <tr key={row.id}>
                    <td>{String(row.id).padStart(2, '0')}</td>
                    <td className="col-2" valign="top">
                      <input
                        type="text"
                        name={`heading_${row.id}`}
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={row.heading}
                        onChange={(e) => {
                          const newRows = [...rows];
                          newRows[index].heading = e.target.value;
                          setRows(newRows);
                        }}
                      />
                    </td>
                    <td>
                      <textarea name={`description_${row.id}`}
                      style={{resize: 'none'}}
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={row.description}
                        onChange={(e) => {
                          const newRows = [...rows];
                          newRows[index].description = e.target.value;
                          setRows(newRows);
                        }}></textarea>
                      
                    </td>
                    <td align="right" className="col-1">
                      <img className="close-btn" src={CloseCircle} title={t('remove')} onClick={() => removeRow(row.id)} alt="Close" />
                    </td>
                  </tr>
                ))}
                
              </tbody>
              </table>
            </div>
               
        </>
        )
}
export default DashboardSection;
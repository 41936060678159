import React, { useState } from 'react';

const Improve = () => {
  const [Q1tables, setQ1Tables] = useState({
    table1: [{ id: 1, heading: '', description: '' }],
    table2: [{ id: 1, heading: '', description: '' }],
    // Add more tables as needed
  });

  const addQ1Row = (tableId) => {
    setQ1Tables((prevTables) => {
      console.log("Previous Tables State:", prevTables); // Debugging: Check previous state

      // Ensure the table exists
      if (!prevTables[tableId]) {
        console.error(`Table with ID ${tableId} does not exist.`);
        return prevTables; // Return unchanged state if table doesn't exist
      }

      // Create a new row
      const newRow = { id: prevTables[tableId].length + 1, heading: '', description: '' };
      console.log("New Row to Add:", newRow); // Debugging: Check new row details

      // Append the new row to the existing rows
      const newRows = [...prevTables[tableId], newRow];
      console.log("New Rows Array:", newRows); // Debugging: Check updated rows array

      // Update the state with the new rows for the specific table
      return { ...prevTables, [tableId]: newRows };
    });
  };

  const removeQ1Row = (tableId, rowId) => {
    setQ1Tables((prevTables) => {
      const newRows = prevTables[tableId]
        .filter(row => row.id !== rowId)
        .map((row, index) => ({
          ...row,
          id: index + 1
        }));
      return { ...prevTables, [tableId]: newRows };
    });
  };

  return (
    <div>
      {Object.keys(Q1tables).map((tableId) => (
        <div key={tableId}>
          <h3>{tableId}</h3>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Heading</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Q1tables[tableId].map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td><input value={row.heading} /></td>
                  <td><input value={row.description} /></td>
                  <td>
                    <button onClick={() => removeQ1Row(tableId, row.id)}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn fm-btn" onClick={() => addQ1Row(tableId)} type="button">
            Add Row to {tableId}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Improve;
